const llm_url = 'https://llm.rbbrduck.co.uk/api/v1/llm/';

export const fetchThreads = async () => {
  const response = await $axios.get(llm_url + 'threads');
  return response.data.data;
}
export const fetchThread = async (barcode) => {
  const response = await $axios.get(llm_url + 'threads/msg?barcode=' + barcode);
  return response.data.data;
}
export const createMessage = async (message, barcode) => {
  const response = await $axios.post(llm_url + 'threads/messages/create', {
    message,
    barcode
  });
  return response.data;
}
export const createThread = async ({
  test_type,
  barcode,
  patient_name,
  patient_gender
}) => {
  const response = await $axios.post(llm_url + 'threads/create', {
    test_type,
    barcode,
    patient_name,
    patient_gender
  });
  return response.data;
}